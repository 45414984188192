import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Dimensions,
  ProductDescription,
  ProductPackageMeasures,
} from "../../../ecommerce/pages/ProductInformation/models/productDescription";
import {
  UpdateProductPackageMeasures,
  addEcommerceBanner,
  createNavbarLink,
  createSalesPerson,
  deleteEcommerceBanner,
  deleteNavbarLink,
  deleteSalesPerson,
  getAllAddresses,
  getAllColors,
  getAllEcommercePreferences,
  getNavbarLinks,
  getSalesPerson,
  getSalesPersonById,
  getWompiPublicKey,
  getWompiPublicKeyBySupplier,
  getEnviaPublicKey,
  updateEcommercePreferences,
  updateNavbarLink,
  updateProductGeneralInfo,
  updateProductImages,
  updateProductMeasurements,
  updateProductPrincipalImage,
  updateProductTechnicalSheet,
  updateSalesPerson,
  updateWompiPublicKey,
  updateEnviaPublicKey,
} from "../../../services";
import { errorToast, successToast } from "../../../services/toasts";
import { fetchCityOptions } from "../../../supplierCheckout/services/getCities";
import { QUERY_KEYS } from "../queryKeys";

export const useGetAllColors = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_COLORS],
    refetchOnWindowFocus: false,
    queryFn: getAllColors,
  });
};

export const useGetAllAddresses = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ADDRESSES],
    refetchOnWindowFocus: false,
    queryFn: () => getAllAddresses(),
  });
};

export const useGetEcommercePreferences = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ECOMMERCE_PREFERENCES],
    refetchOnWindowFocus: false,
    queryFn: getAllEcommercePreferences,
  });
};

export const useGetWompiPublicKey = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_WOMPI_PUBLIC_KEY],
    queryFn: () => getWompiPublicKey(),
    refetchOnWindowFocus: false,
  });
};

export const useGetWompiPublicKeyBySupplier = (supplierName: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_WOMPI_PUBLIC_KEY_BY_SUPPLIER, supplierName],
    queryFn: () => getWompiPublicKeyBySupplier(supplierName),
    refetchOnWindowFocus: false,
  });
};

export const useUpdateWompiPublicKey = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ wompiKey }: { wompiKey: string }) =>
      updateWompiPublicKey(wompiKey),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_WOMPI_PUBLIC_KEY],
      });
      successToast("La llave pública de Wompi se actualizó correctamente");
    },
    onError: (error) => {
      errorToast(
        "Error al actualizar la llave pública de Wompi por favor intenta de nuevo"
      );
    },
  });
};

export const useGetEnviaPublicKey = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ENVIA_PUBLIC_KEY],
    queryFn: () => getEnviaPublicKey(),
    refetchOnWindowFocus: false,
  });
};

export const useUpdateEnviaPublicKey = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ enviaKey }: { enviaKey: string }) =>
      updateEnviaPublicKey(enviaKey),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ENVIA_PUBLIC_KEY],
      });
      successToast("La llave pública de Envia se actualizó correctamente");
    },
    onError: (error) => {
      errorToast(
        "Error al actualizar la llave pública de Envia por favor intenta de nuevo"
      );
    },
  });
};

export const useUpdateEcommercePreferences = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ ecommerceData }: { ecommerceData: FormData }) =>
      updateEcommercePreferences(ecommerceData),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ECOMMERCE_PREFERENCES],
      });
    },
    onError: (error) => {
      errorToast(
        "Error al actualizar las preferencias de E-Commerce por favor intenta de nuevo"
      );
    },
  });
};

export const useUpdateProductGeneralInfo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      productId,
      productData,
    }: {
      productId: number;
      productData: ProductDescription;
    }) => updateProductGeneralInfo(productId, productData),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PRODUCT_BY_ID, data.id],
      });
    },
    onError: (error) => {
      errorToast("Error al actualizar el producto por favor intenta de nuevo");
    },
  });
};

export const useUpdateProductMeasurements = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      productId,
      productMeasurements,
    }: {
      productId: number;
      productMeasurements: Dimensions;
    }) => updateProductMeasurements(productId, productMeasurements),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PRODUCT_BY_ID, data.id],
      });
    },
  });
};

export const useUpdateProductPackageMeasures = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      productId,
      packingPresentationId,
      productPackageMeasures,
    }: {
      productId: number;
      packingPresentationId: number;
      productPackageMeasures: ProductPackageMeasures;
    }) =>
      UpdateProductPackageMeasures(
        productId,
        packingPresentationId,
        productPackageMeasures
      ),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PRODUCT_BY_ID, data.id],
      });
    },
  });
};

export const useUpdatePrincipalImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      productId,
      imageId,
      imageFile,
    }: {
      productId: number;
      imageId: number | null;
      imageFile: FormData;
    }) => updateProductPrincipalImage(productId, imageId, imageFile),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PRODUCT_BY_ID, data.id],
      });
    },
  });
};

export const useUpdateImages = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      productId,
      formDataForRequest,
    }: {
      productId: number;
      formDataForRequest: FormData;
    }) => updateProductImages(productId, formDataForRequest),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PRODUCT_BY_ID, data.id],
      });
    },
  });
};

export const useUpdateTechnicalSheet = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      productId,
      technicalSheet,
    }: {
      productId: number;
      technicalSheet: FormData;
    }) => updateProductTechnicalSheet(productId, technicalSheet),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PRODUCT_BY_ID, data.id],
      });
    },
    onError: (error) => {
      errorToast("Error al actualizar la ficha técnica");
    },
  });
};

export const useAddEcommerceBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ bannerFile }: { bannerFile: FormData }) =>
      addEcommerceBanner(bannerFile),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ECOMMERCE_PREFERENCES],
      });
      successToast("El banner ha sido agregado con éxito");
    },
    onError: (error) => {
      errorToast("Error al agregar el Banner");
    },
  });
};

export const useDeleteEcommerceBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ bannerId }: { bannerId: number }) =>
      deleteEcommerceBanner(bannerId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ECOMMERCE_PREFERENCES],
      });
      successToast("El banner ha sido eliminado con éxito");
    },
    onError: () => {
      errorToast("Error al eliminar el Banner");
    },
  });
};

// ============================================================
// SALES PERSON QUERIES
// ============================================================

export const useGetSalesPerson = () => {
  return useQuery<SalesPeople, Error>({
    queryKey: [QUERY_KEYS.GET_SALES_PERSON],
    queryFn: () => getSalesPerson(),
    refetchOnWindowFocus: false,
  });
};

export const useGetSalespersonCities = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_CITIES],
    queryFn: () => fetchCityOptions(),
    refetchOnWindowFocus: false,
  });
};

export const useGetSalesPersonById = (id: string) => {
  return useQuery<SalesPeople, Error>({
    queryKey: [QUERY_KEYS.GET_SALES_PERSON_BY_ID, id],
    queryFn: () => getSalesPersonById(id),
    enabled: !!id,
  });
};

export const useCreateSalesPerson = () => {
  return useMutation({
    mutationFn: ({ salesPersonData }: { salesPersonData: FormData }) =>
      createSalesPerson(salesPersonData),
    onSuccess: () => {
      successToast("El vendedor ha sido creado con éxito");
    },
    onError: (error: Error) => {
      errorToast(error.message);
    },
  });
};

export const useUpdateSalesPerson = () => {
  return useMutation({
    mutationFn: ({
      salesPersonId,
      salesPersonData,
    }: {
      salesPersonId: number;
      salesPersonData: any;
    }) => updateSalesPerson(salesPersonId, salesPersonData),
    onSuccess: () => {
      successToast("El vendedor ha sido actualizado con éxito");
    },
    onError: () => {
      errorToast("Error al actualizar el vendedor");
    },
  });
};

export const useDeleteSalesPerson = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (salesPersonId: number) => deleteSalesPerson(salesPersonId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_SALES_PERSON],
      });
      successToast("El vendedor ha sido eliminado con éxito");
    },
    onError: () => {
      errorToast("Error al eliminar el vendedor");
    },
  });
};

// ============================================================
// NAVBAR LINKS QUERIES
// ============================================================

export const useGetNavbarLinks = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_NAVBAR_LINKS],
    queryFn: getNavbarLinks,
    refetchOnWindowFocus: false,
  });
};

export const useCreateNavbarLink = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newLink: NewNavbarLink) => createNavbarLink(newLink),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_NAVBAR_LINKS],
      });
      successToast("El enlace ha sido agregado con éxito");
    },
    onError: () => {
      errorToast("Error al agregar el enlace");
    },
  });
};

export const useDeleteNavbarLink = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (linkId: number) => deleteNavbarLink(linkId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_NAVBAR_LINKS],
      });
      successToast("El enlace ha sido eliminado con éxito");
    },
    onError: () => {
      errorToast("Error al eliminar el enlace");
    },
  });
};

export const useUpdateNavbarLink = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (link: { id: number; name: string; url: string }) =>
      updateNavbarLink(link),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_NAVBAR_LINKS],
      });
      successToast("El enlace ha sido actualizado con éxito");
    },
    onError: () => {
      errorToast("Error al actualizar el enlace");
    },
  });
};
