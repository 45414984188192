import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Logo from "../../../assets/svg/logob.svg";
import { useStyles } from "../../../services/muiStyles";
import Drawerlinks from "./DrawerLinks";
import ProfileMenu from "./ProfileMenu";
import useCompanyStore from "../../../Store/supplierCompany/companyData";
import Meta from "../../../components/Meta/Meta";

function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const { companyLogo, companyName } = useCompanyStore();
  const link = companyName
    ? `/${companyName}/productos`
    : "https://fliping.co/";

  useEffect(() => {
    isMobile ? setOpen(false) : setOpen(true);
  }, [isMobile]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <Meta title={companyName} />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.container}>
            <Hidden xsDown implementation="css">
              {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon style={{ color: "white" }} />
              </IconButton> */}
            </Hidden>
            <Hidden smUp implementation="css">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon style={{ color: "black" }} />
              </IconButton>
            </Hidden>
            <a href={link}>
              <div className={classes.logoContainer}>
                <img
                  src={companyLogo || Logo}
                  alt="r1d"
                  className={classes.logoImage}
                />
              </div>
            </a>
          </div>
          <ProfileMenu />
        </Toolbar>
      </AppBar>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}></div>
          <Divider />
          <Drawerlinks isMobile={false} />
        </Drawer>
      </Hidden>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Drawerlinks isMobile={true} />
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div>{props.content}</div>
      </main>
    </div>
  );
}

export default MiniDrawer;
